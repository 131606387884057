.Container {
  flex: 1;
  height: 100vh;
  background:
    radial-gradient(
      /* circle at bottom left , */
      circle  at 5% 75%,
      rgba(210, 159, 255, 1),
      transparent 600px
    ),
    radial-gradient(
      ellipse at top right,
      rgba(170, 180, 240, 0.8) 10%,
      transparent 800px
    ),
    radial-gradient(
      circle at 90% 85%,
      rgba(229, 162, 215, 0.9) 15%,
      transparent 600px
    );
  background-size: 100% 100%;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  line-height: 0.8;
  padding-top: 4%;
}

.secondaryTitleContainer {
  line-height: 1;
  padding-top: 3%;
}

.secondaryTitle {
  font-size: 80px;
  color: #380066;
}

.mainTitle {
  font-size: 50px;
  font-weight: 800;
  color: #A895C5;
}

.text-emphasis {
  font-size: 110px;
  font-weight: 600;
}

.mainContent {
  max-width: 50%;
  padding-top: 1%;
  margin: 0 auto;
}

.playfair-display {
  font-family: "Playfair Display", serif;
}

.questrial {
  font-family: "Questrial", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.dm-serif-display {
  font-family: "DM Serif Display", serif;
}

.text-gradient {
  background: linear-gradient(0deg,#D047A0, #AD38DD  90%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.buttonContainer {
  margin-top: 4%;
}

.registerButton {
  color: white;
  background-color: #7235B7;
  border: 0px;
  border-radius: 8px;
  height: 45px;
  width: 165px;
  font-size: 17px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
  cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .Container {
    background:
      radial-gradient(
        circle  at 5% 75%,
        rgba(210, 159, 255, 0.7),
        transparent 600px
      ),
      radial-gradient(
        ellipse at top right,
        rgba(170, 180, 240, 0.7) 10%,
        transparent 600px
      ),
      radial-gradient(
        circle at 90% 85%,
        rgba(229, 162, 215, 0.7) 10%,
        transparent 550px
      );
  }

  .mainContent {
    max-width: 60%;
  }

}
@media screen and (max-width: 767px) {
  .Container {
    background:
      radial-gradient(
        ellipse at top right,
        rgba(170, 180, 240, 0.6) 10%,
        transparent 600px
      ),
      radial-gradient(
        circle at 90% 75%,
        rgba(229, 162, 215, 0.6) 15%,
        transparent 400px
      ),
      radial-gradient(
        circle  at 5% 75%,
        rgba(210, 159, 255, 0.6),
        transparent 550px
      );
  }

  .mainContent {
    max-width: 70%;
    padding-top: 3%;
  }

  .titleContainer { 
    padding-top: 10%;
  }

  .mainTitle {
    font-size: 35px;
  }

  .secondaryTitleContainer {
    line-height: 1;
    padding-top: 8%;
  }

  .secondaryTitle {
    font-size: 50px;
  }

  .text-emphasis {
    font-size: 60px;
  }

  .buttonContainer {
    margin-top: 10%;
  }
}